<template>
  <EuiContainer v-if="group" style="height: calc(100vh - 180px);">
    <add-users-dialog
      @updateUsers="updateUsers"
      @closeDialog="isAddUsersDialogOpen = false"
      v-if="isAddUsersDialogOpen"
    />
    <confirmation-dialog v-if="itemsToDelete.length > 0"
                         :title="$tc('settings.groups.edit.user.confirmation.title', itemsToDelete.length)"
                         :confirmText="$tc('settings.groups.edit.user.confirmation.confirmText', itemsToDelete.length)"
                         :cancelText="$tc('settings.groups.edit.user.confirmation.cancelText', itemsToDelete.length)"
                         type="danger"
                         @cancel="onClose"
                         @confirm="onConfirm">
      <p v-html="$tc('settings.groups.edit.user.confirmation.content', itemsToDelete.length, { group: sanitizedGroup })"/>
      <ul class="mt-2 list-none"><li v-for="(item, index) in itemsToDelete" :key="index"><b>{{ item.fullname }}</b> - {{ item.email }}</li></ul>
    </confirmation-dialog>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiButton iconOnly size="large" variant="text" @click="goBack()">
              <EuiIcon name="chevron_left" color="primary"/>
            </EuiButton>
            <EuiHeading :level="1" bold>{{ group.name }}</EuiHeading>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('settings.groups.create.addUsers.title')">
            <template v-slot:actions>
              <EuiButton variant="text" color="primary" size="small" @click="openDialog">{{ $t('button.add') }}</EuiButton>
            </template>
            <EuiGrid>
              <EuiGridRow>
                <EuiGridColumn>
                  <span class="font-bold">
                    <template v-if="selected.length">
                      {{ $tc('settings.groups.create.addUsers.label.selectedUsers', selected.length) }}
                    </template>
                    <template v-else>
                      {{ $tc('settings.groups.create.addUsers.label.nbUsers', users.length) }}
                    </template>
                    <EuiButton :style="{visibility: selected.length ? 'visible' : 'hidden'}" variant="text" color="primary" @click="openDeleteConfirmationDialog(selected)">
                      {{ $t('settings.users.button.delete') }}
                    </EuiButton>
                  </span>
                </EuiGridColumn>
                <EuiGridColumn>
                  <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                            v-model.trim="searchInput"
                            @keyup.enter="searchUser()">
                    <template #endEnhancer>
                      <div class="eui-m-cardFilter__clear">
                        <template v-if="searchInput && searchInput.length > 0">
                          <EuiButton iconOnly size="small" color="primary" @click.stop="resetSearchInput">
                            <EuiIcon name="close"/>
                          </EuiButton>
                          <div class="eui-m-cardFilter__separator"></div>
                        </template>
                      </div>
                      <EuiButton iconOnly color="primary" size="small" @click.stop="searchUser()">
                        <EuiIcon name="search"/>
                      </EuiButton>
                    </template>
                  </EuiInput>
                </EuiGridColumn>
              </EuiGridRow>
            </EuiGrid>
            <EuiTable v-if="fetchedUsers.length > 0">
              <EuiTableHeader>
                <EuiTableRow>
                  <EuiTableHead dataType="input">
                    <EuiCheckbox v-model="selectAllUsers"/>
                  </EuiTableHead>
                  <EuiTableHead>{{ $t('settings.groups.create.addUsers.table.headers.name') }}</EuiTableHead>
                  <EuiTableHead>{{ $t('settings.groups.create.addUsers.table.headers.email') }}</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <!-- table row -->
                <EuiTableRow v-for="(item, index) in users" :key="index">
                  <EuiTableCell dataType="input" @click.native.stop="">
                    <EuiCheckbox :value="item" v-model="selected"/>
                  </EuiTableCell>
                  <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                  <EuiTableCell>{{ item.email }}</EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('settings.groups.create.userPermissions.title')">
            <EuiCheckbox v-model="selectAll" name="selectAll">
              {{ $t('settings.groups.edit.allRights.label') }}
            </EuiCheckbox>
            <EuiTable>
              <EuiTableHeader>
                <EuiTableRow class="cursor-default">
                  <EuiTableHead> </EuiTableHead>
                  <EuiTableHead>{{$t('settings.groups.edit.permissions.table.headers.createUpdate') }}</EuiTableHead>
                  <EuiTableHead>{{ $t('settings.groups.edit.permissions.table.headers.list') }}</EuiTableHead>
                  <EuiTableHead>{{ $t('settings.groups.edit.permissions.table.headers.other') }}</EuiTableHead>
                </EuiTableRow>
              </EuiTableHeader>
              <EuiTableBody>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.edit.permissions.table.headers.parapher') }}</EuiTableHead>
                </EuiTableRow>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableCell>{{ $t('settings.groups.edit.permissions.table.headers.templates') }}</EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox v-model="group.hasWriteModelPermission" name="hasWriteModelPermission"/>
                  </EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox v-model="group.hasReadModelPermission" name="hasWriteModelPermission"/>
                  </EuiTableCell>
                  <EuiTableCell>
                  </EuiTableCell>
                </EuiTableRow>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableCell class="align-middle">{{ $t('settings.groups.edit.permissions.table.headers.sharedTemplates') }}</EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox v-model="group.hasWholeSignbooksEditingPermission" name="hasWholeSignbooksEditingPermission"/>
                  </EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox v-model="group.hasWholeSignbooksListingPermission" name="hasWholeSignbooksListingPermission"/>
                  </EuiTableCell>
                  <EuiTableCell>
                  </EuiTableCell>
                </EuiTableRow>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableHead colspan="4" class="py-2 font-bold bg-lightest-grey">{{ $t('settings.groups.edit.permissions.table.headers.users') }}</EuiTableHead>
                </EuiTableRow>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableCell>{{ $t('settings.groups.edit.permissions.table.headers.userRights') }}</EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox v-model="group.hasEditUserPermission" name="hasEditUserPermission"/>
                  </EuiTableCell>
                  <EuiTableCell>
                    <EuiCheckbox checked disabled/>
                  </EuiTableCell>
                  <EuiTableCell class="flex">
                    <EuiCheckbox v-model="group.hasAccessRulesPermission" name="hasAccessRulesPermission">
                      {{ $t('settings.groups.edit.permissions.table.other.managePermissions') }}
                    </EuiCheckbox>
                  </EuiTableCell>
                </EuiTableRow>
                <EuiTableRow class="hover:bg-transparent cursor-default">
                  <EuiTableCell class="align-middle">
                    {{ $t('settings.groups.edit.permissions.table.headers.manageSocialSignatureAuthorizations') }}
                  </EuiTableCell>
                  <EuiTableCell>
                  </EuiTableCell>
                  <EuiTableCell>
                  </EuiTableCell>
                  <EuiTableCell class="flex">
                    <EuiCheckbox v-model="group.hasSocialSignerTriggerPermission" name="hasSocialSignerTriggerPermission">
                      {{ $t('settings.groups.edit.permissions.table.other.manageSocialSignatureAuthorizations') }}
                    </EuiCheckbox>
                  </EuiTableCell>
                </EuiTableRow>
              </EuiTableBody>
            </EuiTable>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn horizontalAlign="end">
          <div>
            <EuiButton  @click="goBack">{{ $t('button.cancel') }}</EuiButton>
            <EuiButton variant="raised" color="primary" @click="updateGroup" :disabled="haveSameProperties(group, tmpGroup)">{{ $t('button.save') }}</EuiButton>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions } from 'vuex';
import DOMpurify from 'dompurify';
// import addUsersDialog from '@/pages/settings/dialog/addUsers';
// import confirmationDialog from '@/pages/settings/dialog/confirmation';

export default {
  /**
   * The name of the page.
   */
  name: 'EditGroup',

  /**
   * The properties that can be used.
   */
  props: {
    /**
     * The group identifier used.
     */
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  /**
   * Available watchers for this page.
   */
  watch: {
    groupId() {
      this.fetchGroup();
    },
  },
  components: {
    addUsersDialog: () => import(/* webpackPrefetch: true */ '@/pages/settings/dialog/addUsers'),
    confirmationDialog: () => import(/* webpackPrefetch: true */ '@/pages/settings/dialog/confirmation'),
  },
  data() {
    return {
      group: null,
      tmpGroup: null,
      isAddUsersDialogOpen: false,
      itemsToDelete: [],
      selected: [],
      users: [],
      fetchedUsers: [],
      searchInput: '',
      searchParams: {
        orders: [{ fieldName: 'lastname', orderBy: 'DESC' }],
        params: [],
        offset: 0,
        limit: 10000,
      },
    };
  },

  /**
   * The computed properties for the component.
   */
  computed: {
    sanitizedGroup() {
      return DOMpurify.sanitize(this.group.name);
    },
    selectAllUsers: {
      get() {
        return this.users ? this.fetchedUsers.length > 0 && this.selected.length === this.users.length : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.users.forEach((user) => {
            selected.push(user);
          });
        }
        this.selected = selected;
      },
    },
    selectAll: {
      get() {
        return this.group.hasReadModelPermission &&
          this.group.hasWriteModelPermission &&
          this.group.hasWholeSignbooksEditingPermission &&
          this.group.hasWholeSignbooksListingPermission &&
          this.group.hasEditUserPermission &&
          this.group.hasAccessRulesPermission &&
          this.group.hasSocialSignerTriggerPermission;
      },
      set(value) {
        this.group.hasReadModelPermission = value;
        this.group.hasWriteModelPermission = value;
        this.group.hasWholeSignbooksEditingPermission = value;
        this.group.hasWholeSignbooksListingPermission = value;
        this.group.hasEditUserPermission = value;
        this.group.hasAccessRulesPermission = value;
        this.group.hasSocialSignerTriggerPermission = value;
      },
    },
  },
  methods: {
    ...mapActions({
      setLastBreadcrumb: 'application/setLastBreadcrumb',
      findById: 'ModuleEdocSign/settingsGroups/findById',
      searchUsers: 'ModuleEdocSign/settingsUsers/search',
      deleteUserFromGroup: 'ModuleEdocSign/settingsGroups/deleteUserFromGroup',
      addUserToGroup: 'ModuleEdocSign/settingsGroups/addUserToGroup',
      update: 'ModuleEdocSign/settingsGroups/update',
    }),
    haveSameProperties(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (let key of keys1) {
        if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          return false;
        }
      }
      return true;
    },
    filterUser(array, string) {
      return array.filter((o) => Object.keys(o).some((k) => {
        if (o[k] && !Array.isArray(o[k])) {
          return o[k].toLowerCase().includes(string.toLowerCase());
        }
      }));
    },
    searchUser() {
      if (this.searchInput) {
        this.users = this.filterUser(this.fetchedUsers, this.searchInput);
      } else {
        this.users = this.fetchedUsers;
      }
    },
    resetSearchInput() {
      this.searchInput = '';
      this.users = this.fetchedUsers;
      // this.searchParams.params = [{ paramName: 'groupId', paramValue: this.groupId }];
      // this.fetchUsers();
    },
    onClose() {
      this.itemsToDelete = [];
    },
    onConfirm() {
      const promises = [];
      this.itemsToDelete.forEach((item) => {
        const payload = {
          groupId: this.groupId,
          userId: item.userId,
        };
        promises.push(this.deleteUserFromGroup(payload));
      });
      Promise.all(promises).finally(() => {
        this.fetchUsers();
        this.onClose();
        this.selected = [];
      });
    },
    openDeleteConfirmationDialog(items) {
      this.itemsToDelete = [...items];
    },
    openDialog() {
      this.isAddUsersDialogOpen = true;
    },
    goBack() {
      this.$router.push({ name: 'settings.groups.index' });
    },
    fetchUsers() {
      this.searchUsers(this.searchParams).then((response) => {
        if (response && response.accounts) {
          this.fetchedUsers = response.accounts;
          this.users = response.accounts;
        }
      });
    },
    fetchGroup() {
      this.setLastBreadcrumb('');
      this.findById(this.groupId).then((response) => {
        if (response) {
          const group = response;
          group.hasReadModelPermission = (response.hasReadModelPermission === '1');
          group.hasWriteModelPermission = (response.hasWriteModelPermission === '1');
          group.hasWholeSignbooksEditingPermission = (response.hasWholeSignbooksEditingPermission === '1');
          group.hasWholeSignbooksListingPermission = (response.hasWholeSignbooksListingPermission === '1');
          group.hasEditUserPermission = (response.hasEditUserPermission === '1');
          group.hasAccessRulesPermission = (response.hasAccessRulesPermission === '1');
          group.hasSocialSignerTriggerPermission = (response.hasSocialSignerTriggerPermission === '1');
          this.setLastBreadcrumb(group.name);
          this.group = group;
          this.tmpGroup = {...group};
        }
      });
    },
    updateUsers(users) {
      const promises = [];
      users.forEach((item) => {
        promises.push(this.addUserToGroup({ group: { id: this.groupId }, user: { userId: item.userId } }));
      });
      Promise.all(promises)
        .finally(() => {
          this.fetchUsers();
          this.isAddUsersDialogOpen = false;
        });
    },
    updateGroup() {
      const group = { ...this.group };
      group.hasReadModelPermission = group.hasReadModelPermission ? '1' : '0';
      group.hasWriteModelPermission = group.hasWriteModelPermission ? '1' : '0';
      group.hasWholeSignbooksEditingPermission = group.hasWholeSignbooksEditingPermission ? '1' : '0';
      group.hasWholeSignbooksListingPermission = group.hasWholeSignbooksListingPermission ? '1' : '0';
      group.hasEditUserPermission = group.hasEditUserPermission ? '1' : '0';
      group.hasAccessRulesPermission = group.hasAccessRulesPermission ? '1' : '0';
      group.hasSocialSignerTriggerPermission = group.hasSocialSignerTriggerPermission ? '1' : '0';
      this.update(this.group).then(() => this.goBack());
    },
  },
  /**
   * This method will be fired once the page has been loaded.
   * It'll fetch the group using the given group identifier.
   */
  mounted() {
    this.searchParams.params.push({
      paramName: 'groupId', paramValue: this.groupId,
    });
    this.fetchUsers();
    this.fetchGroup();
  },
};
</script>
